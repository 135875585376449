<template>
  <el-card class="box-card full-screen recharge-apply">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('orgName')">
          <el-select v-model="queryForm.orgInfoId" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option v-for="(t,i) in orgList" :key="i" :label="t.name+' -- '+(t.orgInfoCode)" :value="t.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tradeAssistant')">
          <el-input v-model="queryForm.createName" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('dealType')">
          <el-select v-model="queryForm.businessCode" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <template v-for="(item,i) in businessList">
              <el-option-group v-if="item.parentId==0" :key="i" :label="$t(item.name)">
                <template v-for="(t,i) in businessList">
                  <el-option v-if="t.parentId==item.id" :key="i" :label="$t(t.name)" :value="t.businessCode"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('customerName')">
          <el-input v-model="queryForm.customerName" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('region')">
          <el-select v-model="queryForm.region" :placeholder="$t('all')" filterable size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <!-- 国 -->
            <template v-for="(item,i) in countryList">
              <el-option-group v-if="item.parentId==0" :key="i" :label="$t(item.name)">
                <!-- 省 -->
                <template v-for="(t,i) in countryList">
                  <el-option v-if="t.parentId==item.id" :key="i" :label="$t(t.name)" :value="t.id"></el-option>
                </template>
              </el-option-group>
            </template>
          </el-select>
        </el-form-item>
        <!-- <el-form-item :label="$t('channel')">
          <el-input v-model="queryForm.transactionCode" size="small"></el-input>
        </el-form-item> -->

        <el-form-item :label="$t('date')">
          <el-date-picker v-model="queryForm.date" type="daterange" range-separator="~" :start-placeholder="$t('startDate')" :end-placeholder="$t('endDate')" value-format="yyyy-MM-dd" size="small" unlink-panels :clearable="false" :picker-options="pickerOptions0" @change='changeDate'>
          </el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('transactionCode')">
          <el-input v-model="queryForm.transactionCode" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('transaction voucher')">
          <el-input v-model="queryForm.credentialNo" size="small"></el-input>
        </el-form-item>
        <el-form-item :label="$t('trading status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('Waiting transaction')" value="0"></el-option>
            <el-option :label="$t('transaction progress')" value="1"></el-option>
            <el-option :label="$t('transaction success')" value="2"></el-option>
            <el-option :label="$t('transaction failing')" value="3"></el-option>
            <el-option :label="$t('buy back progress')" value="4"></el-option>
            <el-option :label="$t('buy back success')" value="5"></el-option>
            <el-option :label="$t('buy back failing')" value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns">
        <a @click="downloadEXCEL()" type="text" size="mini" :disabled="!tableData.total"><i class="el-icon-download"></i> {{$t('downloadEXCEL')}}</a>
      </el-row>
    </div>
    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" min-width="50"></el-table-column>
        <el-table-column prop="orgName" fixed="left" :label="$t('orgName')" min-width="160"></el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="100">
          <template slot-scope="scope"><span class="franc">₣</span>{{Number(scope.row.amount).formatMoney(0)}}</template>
        </el-table-column>
        <el-table-column prop="transactionCode" :label="$t('transactionCode')" min-width="200"></el-table-column>
        <el-table-column prop="credentialNo" :label="$t('transaction voucher')" min-width="160"></el-table-column>
        <el-table-column prop="businessName" :label="$t('dealType')" min-width="200">
          <template slot-scope="scope">
            {{$t(scope.row.businessName)}}
            ( <span v-if="scope.row.amount>0">{{$t('income')}}</span> <span v-if="scope.row.amount<0">{{$t('expenditure')}}</span> )
          </template>
        </el-table-column>
        <el-table-column prop="customerName" :label="$t('customerName')" min-width="160"></el-table-column>
        <el-table-column prop="createName+createSurname" :label="$t('tradeAssistant')" min-width="160">
          <template slot-scope="scope">{{scope.row.createName +" "+ scope.row.createSurname}}</template>
        </el-table-column>
        <el-table-column prop="region" :label="$t('region')" min-width="160">
          <template slot-scope="scope">{{formatRegion(scope.row.region) }}</template>
        </el-table-column>
        <el-table-column prop="type" :label="$t('accountType')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">{{$t('cash account')}}</span>
            <span v-if="scope.row.type==2">{{$t('virtual account')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('trading status')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0">{{$t('Waiting transaction')}}</span>
            <span v-else-if="scope.row.status==1">{{$t('transaction progress')}}</span>
            <span v-else-if="scope.row.status==2">{{$t('transaction success')}}</span>
            <span v-else-if="scope.row.status==3">{{$t('transaction failing')}}</span>
            <span v-else-if="scope.row.status==4">{{$t('buy back progress')}}</span>
            <span v-else-if="scope.row.status==5">{{$t('buy back success')}}</span>
            <span v-else-if="scope.row.status==6">{{$t('buy back failing')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transactionRemark" :label="$t('remark')" min-width="160">
          <template slot-scope="scope">
            <div class="overstep-hidden"> {{scope.row.transactionRemark}}</div>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" :label="$t('creationTime')" min-width="160">
          <template slot-scope="scope">{{$manba(scope.row.createTime).format('ymdhms')}}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />
  </el-card>
</template>

<script>
import transactionInfo from '@/api/transactionInfo'

import country from '@/api/country'
import business from '@/api/business'
import org from '@/api/org'
import qs from 'qs'

export default {
  name: "tradingRecord",
  data () {
    return {
      pickerOptions0: {
        onPick: ({ maxDate, minDate }) => {
          this.minDate = minDate;
          this.maxDate = maxDate;
        },
        disabledDate: (time) => {
          let today = 3 * 30 * 24 * 3600 * 1000;
          if (this.minDate) {
            return time.getTime() > Date.now() || time > new Date(this.minDate.getTime() + today) || time < new Date(this.minDate.getTime() - today)
          }
          return time.getTime() > Date.now();
        },
      },
      // countryList: Array,

      isLoading: false,
      //table高度，动态设置
      queryTableHeight: String,
      tableData: {},
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      /**查询条件 */
      queryForm: { date: [new Date(this.$manba().format('ym') + "-01"), new Date()] },
      businessList: [],
      /**是否是明细 */
      isDetail: true,

      orgList: []
    }
  },
  computed: {
    downloadUrl () {
      let _this = this;
      let param = JSON.parse(JSON.stringify(_this.queryForm));
      let params = {};
      for (let k in param) {
        if (k == "date" && param[k] && param[k].length == 2) {
          params.startTime = _this.$manba(param.date[0]).format('ymd');
          params.endTime = _this.$manba(param.date[1]).format('ymd');
        }
        else if (param[k]) {
          params[k] = param[k]
        }
      }
      delete params.date;
      delete params.size;
      delete params.current;

      return transactionInfo.getPageExport + '?' + qs.stringify(params)
    },
    countryList () { return this.$store.state.globalParameter.countryList; },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  watch: {
    "queryForm.date" (newVal) {
      if (newVal && newVal.length > 0) {
        this.queryForm.startTime = this.queryForm.date[0];
        this.queryForm.endTime = this.queryForm.date[1];
      } else {
        this.queryForm.startTime = undefined;
        this.queryForm.endTime = undefined;
      }
    }
  },
  methods: {
    formatRegion (region) {
      let p1 = this.countryList.find(function (c) { return c.id == region });
      let p2 = this.countryList.find(function (c) { return c.id == p1.parentId });
      return p2.name + "/" + p1.name
    },
    downloadEXCEL () {
      if (!this.tableData.total) return;
      if (this.$manba(this.queryForm.date[1]).distance(this.queryForm.date[0]) > 90) {
        this.$confirm(this.$('The end time cannot be later than 90 days after the start time')).then(() => { }).catch(() => { });
        return;
      }
      let download = document.createElement("a");
      download.href = this.downloadUrl;
      download.download = '';
      download.click();
    },
    getList () {
      let _this = this;

      _this.isLoading = true;
      let params = JSON.parse(JSON.stringify(_this.queryForm))
      if (params.date && params.date.length > 0) {
        params.startTime = _this.$manba(params.date[0]).format('ymd');
        params.endTime = _this.$manba(params.date[1]).format('ymd');
      }
      else {
        params.startTime = undefined;
        params.endTime = undefined;
      }
      delete params.date;

      transactionInfo.getPage({
        param: {
          current: _this.currentPage,
          size: _this.pageSize,
          ...params
        },
        success: function (res) {
          _this.isLoading = false;
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },

    getBusiness () {
      let _this = this;
      business.getList({
        success: res => {
          _this.businessList = res.data.reverse();
          _this.getList();
        }
      })
    },

    getOrgList () {
      let _this = this;
      org.getOrgInfoPage({
        param: { current: 1, size: 10000, },
        success: res => {
          if (res.data)
            _this.orgList = res.data.records;
        }
      })
    },
    changeDate (e) {
      if (e === null) {
        this.minDate = "";
        this.maxDate = "";
        this.pickerBeginDateBefore = {
          disabledDate: (time) => {
            let three = 3 * 30 * 24 * 3600 * 1000;
            if (this.minDate) {
              return time.getTime() > Date.now() || time > new Date(this.minDate.getTime() + three) || time < new Date(this.minDate.getTime() - three)
            }
            return time.getTime() > Date.now();
          },
        };
      }
    },
    getCountry () {
      let _this = this;
      country.getList({
        success: res => {
          _this.$set(_this, "countryList", res.data);
        }
      })
    },
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getBusiness();
    // this.getCountry();
    if (this.loginInfo.orgType == 1) { this.getOrgList(); }
  },
}
</script>

<style>
</style>